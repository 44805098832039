import React, { PureComponent, Suspense } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Row, Col, Spin, Layout, Tooltip, Button } from 'antd';
import { LogoutOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { MenuItems } from './dashboard-menu.constants';
import logo from '../../assets/logo.png';

const { Header } = Layout;

class Dashboard extends PureComponent {
  state = {
    dashboardItem: this.props.menuItem,
    collapsed: false,
  };

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  showDashboardSide = (item) => {
    this.setState({ dashboardItem: item });
  };

  renderDashboardItem = () => {
    const { dashboardItem } = this.state;
    const DashboardItem = React.lazy(() => import(`/${dashboardItem}/${dashboardItem}`));
    return <DashboardItem leaseId={this.props.leaseId} />;
  };

  onLogout = () => {
    this.props.onLogout();
    this.props.history.push('/login');
    window.location.reload();
  };

  render() {
    const { currentUser } = this.props;
    if (!currentUser) {
      window.location.reload();
    }

    return (
      <Row>
        <Col span={4}>
          <div className="menuHeader">
            <img className="logo" src={logo} alt="conjure" />
            <Button onClick={this.toggleCollapsed}>
              {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
            </Button>
          </div>
          <Menu mode="inline">
            {MenuItems.map((menuItem, index) => {
              const viewMenuItemName = menuItem.replace(' ', '');
              return (
                <Menu.Item key={index} onClick={() => this.showDashboardSide(viewMenuItemName)}>
                  <Link to={viewMenuItemName.toLowerCase()}>{menuItem}</Link>
                </Menu.Item>
              );
            })}
          </Menu>
        </Col>
        <Col span={20}>
          <Header>
            <div className="wrapper">
              <h1>Dashboard</h1>
              <h4
                style={{
                  marginLeft: 'auto',
                  padding: '0 12px',
                }}
              >
                <Link to="/account">{currentUser.fullName}</Link>
              </h4>
              <Tooltip title="Log out">
                <Button
                  style={{
                    margin: '0px 8px 6px 0px',
                  }}
                  onClick={this.onLogout}
                  shape="circle"
                  icon={<LogoutOutlined />}
                >
                  <Link to="login" />
                </Button>
              </Tooltip>
            </div>
          </Header>
          <Suspense fallback={<Spin />}>{this.renderDashboardItem()}</Suspense>
        </Col>
      </Row>
    );
  }
}
export default withRouter(Dashboard);
