import api from './api.service';

class AuthService {
  /* Auth */
  async login(username, password) {
    const response = await api.post('/api/auth/login', {
      username,
      password,
    });

    const { data } = response;

    if (data.accessToken) {
      localStorage.setItem('user', JSON.stringify(response.data));
    }

    return data;
  }

  logout() {
    localStorage.removeItem('user');
  }

  // TODO: only admin users can do this.
  async registerUser(username, email, password) {
    return api.post('/api/user/sign-up', {
      username,
      email,
      password,
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  changePassword(oldPassword, newPassword) {
    return api.post('/api/auth/change-password', {
      oldPassword,
      newPassword,
    });
  }
}

export default new AuthService();
