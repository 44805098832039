import React, { PureComponent, useState } from "react";
import "antd/dist/antd.css";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Card, Spin } from "antd";
import logo from "../../assets/logo.png";
import authService from "../../services/auth.service";
import { get } from "lodash";

const LoginForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();

  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      await authService.login(values.username, values.password);
      setIsLoading(false);
      history.push("/dashboard/neworders");
      document.location.reload();
    } catch (e) {
      const errorMessage = get(e, "response.data.message");
      setErrorMessage(errorMessage);
      setIsLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const LogInComponent = (
    <Card
      style={{ width: 400 }}
      cover={
        <img
          alt="example"
          style={{
            margin: "16px auto 16px auto",
            width: 200,
          }}
          src={logo}
        />
      }
    >
      <Form
        layout={"vertical"}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button style={{ width: "100%" }} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
        {errorMessage && (
          <div
            style={{
              textAlign: "center",
              color: "red",
              fontWeight: "bold",
            }}
          >
            {errorMessage}
          </div>
        )}
      </Form>
    </Card>
  );

  return (
    <div>
      <Spin spinning={isLoading}>{LogInComponent}</Spin>
    </div>
  );
};

class Login extends PureComponent {
  render() {
    return <LoginForm />;
  }
}

export default Login;
