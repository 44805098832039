import axios from 'axios';

const getHeaders = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  const headers = {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
  };

  if (user && user.accessToken) {
    headers['x-access-token'] = user.accessToken;
  }

  return headers;
};

export default axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 200000,
  headers: getHeaders(),
});
