import React, { PureComponent, useState } from 'react';
import 'antd/dist/antd.css';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

import { Form, Input, Button, Card } from 'antd';
import authService from '../../services/auth.service';

const AccountForm = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');

  const onFinish = async ({ oldPassword, newPassword }) => {
    try {
      // setIsLoading(true)
      setErrorMessage(null);
      const res = await authService.changePassword(oldPassword, newPassword);
      setMessage(res.data.message);
      // setIsLoading(false);
    } catch (e) {
      const error = get(e, 'response.data.message');
      setErrorMessage(error);
      // setIsLoading(false);
    }
  };

  return (
    <Card style={{ width: 600, margin: '100px auto' }}>
      <Form layout="horizontal" onFinish={onFinish}>
        <Form.Item
          label="Old Password"
          name="oldPassword"
          rules={[{ required: true, message: 'Please input your old password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[{ required: true, message: 'Please input your new password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button style={{ marginRight: 8 }} htmlType="submit" type="primary">
            Submit
          </Button>
          <Link to="/">Back</Link>
        </Form.Item>
        {errorMessage && (
          <div
            style={{
              textAlign: 'center',
              color: 'red',
              fontWeight: 'bold',
            }}
          >
            {errorMessage}
          </div>
        )}
        {message && (
          <div
            style={{
              textAlign: 'center',
              color: 'green',
              fontWeight: 'bold',
            }}
          >
            {message}
          </div>
        )}
      </Form>
    </Card>
  );
};

class Account extends PureComponent {
  render() {
    return (
      <div>
        <AccountForm />
      </div>
    );
  }
}

export default Account;
