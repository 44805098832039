export const MenuItems = [
  'New Orders',
  'Orders',
  'Lease',
  'Customers',
  'Products',
  'Assets',
  'ASN',
  'Vendors',
  'Reporting',
];
