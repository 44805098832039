import React from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import 'antd/dist/antd.css';

import authService from '../services/auth.service';
import Account from '../components/Account/Account';
import Login from '../components/Account/Login';
import Dashboard from '../components/Dashboard/Dashboard';
import Payment from '../components/Payment';
import logo from '../assets/logo.png';
import { MenuItems } from '../components/Dashboard/dashboard-menu.constants';
import ProtectedRoute from '../shared/ProtectedRoute';

import './App.css';

const { Header, Content } = Layout;

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authService.getCurrentUser(),
    };
  }

  componentWillUpdate() {
    const user = authService.getCurrentUser();
    this.setState({
      currentUser: user,
    });
  }

  onLogout = () => {
    authService.logout();
  };

  renderDashboard = (menuItem = 'orders', o) => {
    const { currentUser } = this.state;
    const { location } = o;
    const query = location.search || '';
    const leaseId = query.substr(4);

    return (
      <div className="dashboard">
        <Dashboard
          onLogout={this.onLogout}
          currentUser={currentUser}
          menuItem={menuItem}
          leaseId={menuItem === 'Lease' ? leaseId : ''}
        />
      </div>
    );
  };

  renderLogin = ({ history, location }) => {
    return (
      <>
        <Header>
          <div className="wrapper">
            <img className="logo" src={logo} alt="conjure" />
          </div>
        </Header>
        <div className="login">
          <Login userLoggedIn />
        </div>
      </>
    );
  };

  render() {
    const { currentUser } = this.state;

    return (
      <div>
        <Layout>
          <Content>
            <BrowserRouter>
              {!currentUser && <Redirect to="/login" />}
              <Switch>
                <ProtectedRoute
                  path="/"
                  exact
                  loggedIn={!!currentUser}
                  component={(o) => <Redirect to="/dashboard/neworders" />}
                />
                <Route path="/login">{currentUser ? <Redirect to="/dashboard/neworders" /> : this.renderLogin}</Route>
                <ProtectedRoute path="/account" loggedIn={!!currentUser} component={() => <Account />} />
                <Route path="/login">{currentUser ? <Redirect to="/dashboard/orders" /> : this.renderLogin}</Route>
                <ProtectedRoute path="/account" loggedIn={!!currentUser} component={() => <Account />} />
                <Route path="/shopify/app" component={Payment} />

                {MenuItems.map((menuItem, index) => {
                  const viewMenuItemName = menuItem.replace(' ', '');
                  return (
                    <ProtectedRoute
                      key={index}
                      path={`/dashboard/${viewMenuItemName.toLowerCase()}`}
                      exact
                      loggedIn={!!currentUser}
                      component={(o) => this.renderDashboard(viewMenuItemName, o)}
                    />
                  );
                })}
              </Switch>
            </BrowserRouter>
          </Content>
        </Layout>
      </div>
    );
  }
}
export default App;
